$fonts-dir: '../fonts/';

$color-black: black;
$color-white: #ffffff;
$color-blue: #516F91;
$color-dark-blue: #24252f;

$sideOffset: 11.25%;
$textVGap: 2.187vw;
$textVGapPortrait: 10vw;
$portraitPadding: 6.25vw;

$transition-duration: 200ms;
$transition-duration500: 500ms;

$sliderNavWidth: 130px;