.slider-img {
    width: 100%;
    border-radius: 20px;
}
.slider-area  {
    position: relative;
}
.slider-nav {
    position: absolute;
    right: 0;
    bottom: calc(30px + 100%);
    display: flex;
    gap: 10px;
    
    @include orientation-portrait {
        position: static;
        justify-content: center;
        margin-top: 20px;
    }

    &__btn {
        border: 2px solid $color-blue;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55px;
        height: 55px;
        cursor: pointer;
        
        &_prev {
            img {
                transform: scaleX(-1);
            }
        }
    }
}
.swiper-button-lock {
    display: none !important;
}
.swiper-button-disabled {
    opacity: .2;
}