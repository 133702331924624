body {
    font-size: 62.5%;
    font-family: 'RotondaC', Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    color: $color-white;
    min-width: 320px;
    background-color: $color-dark-blue;
    /* padding-bottom: 120px;

    @include break-md-down {
        padding-bottom: 24px;
    } */
  }
img {
    max-width: 100%;
}
.container {
    max-width: 1270px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}
.page {
    // min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 4.37vw;
    overflow: hidden;
    @include orientation-portrait {
        padding: 0 $portraitPadding;
        row-gap: 9vw;
    }
}

.badge {
    position: relative;
    width: 17%;
    margin-top: 6%;
    margin-left: $sideOffset;
    &__image {
        width: 79%;
    }
    &__text {
        font-family: 'Gotham Pro';
        font-size: .875vw;
        margin-top: 1.875vw;
        line-height: 1.3;
        @include orientation-portrait {
            font-size: 3.75vw;
            margin-top: 3.5vw;
        }
    }
    @include orientation-portrait {
        margin-left: 0;
        margin-top: $portraitPadding;
        width: 100%;
        padding-right: 50%;
        padding-bottom: 10vw;
    }
}
.page-decor-wrapper {
    flex: 0 0 35.6vw;
    max-width: 35.6vw;
    position: relative;
    @include orientation-portrait {
        display: none;
    }
}
.page-decor {
    position: absolute;
    bottom: 0;
    left: 0;
    object-fit: contain;
    object-position: 0% 100%;
    max-width: 100%;
    height: 100%;
}

.button {
    text-transform: uppercase;
    font-size: 1.125vw;
    color: $color-white;
    border: 2px solid $color-blue;
    padding: 0.85em 3em;
    border-radius: clamp(27px, 1.5em, 1.5em);
    display: flex;
    align-items: center;
    width: fit-content;
    gap: .72em;
    @include transition-regular;
    @include orientation-portrait {
        margin-left: auto;
        margin-right: auto;
        font-size: 4.678vw;
    }
    &__icon {
        width: 1.16em;
        height: 1.16em;
        use {
            @include transition-regular;
            fill:none;
            stroke: $color-blue;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
        }
    }
    &:hover {
        background-color: $color-blue;
    }
    &:hover &__icon use {
        stroke: $color-white;
    }
}

.content {
    // padding-left: 42%;
    display: flex;
    gap: 6.25vw;
    position: relative;
    @include orientation-landscape {
        padding-right: $sideOffset;
    }
    h1 {
        font-size: 3.75vw;
        font-weight: normal;
        margin-bottom: $textVGap;
        @include orientation-portrait {
            font-size: 11.25vw;
            margin-bottom: $textVGapPortrait;
        }
    }
    ul {
        margin-bottom: $textVGap;
        @include orientation-portrait {
            margin-bottom: $textVGapPortrait;
        }
    }
    li {
        list-style-type: none;
        position: relative;
        padding-left: 1.5em;
        font-size: 1.125vw;
        font-family: 'Gotham Pro';
        &:not(:last-child) {
            margin-bottom: .8em;
            @include orientation-portrait {
                margin-bottom: 1.3em;
            }
        }
        &::before {
            content: '\2014';
            position: absolute;
            top: 0;
            left: 0;
            color: $color-blue;
        }
        @include orientation-portrait {
            font-size: 4.375vw;
        }
    }
    &__inner {
        padding-bottom: 8.75vw;
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.under-construction-image {
    position: absolute;
    width: 16%;
    left: 70%;
    top: -35%;
    animation: spin 15s linear infinite;
    @include orientation-portrait {
        left: 50%;
        width: 50%;
        top: 0;
        max-height: 100%;
        object-fit: contain;
        .content & {
            display: none;
        }
    }
    .badge & {
        @include orientation-landscape {
            display: none;
        }
    }
}
.page-text {
    &__inner {
        @include orientation-landscape {
            margin-left: 16%;
        }        
    }
}

.section-title {
    font-size: 36px;
    margin-bottom: .7em;
    line-height: 1.3;
    &_in-slider {
        padding-right: $sliderNavWidth;
        @include orientation-portrait {
            padding-right: 0;
        }
    }
    @include orientation-portrait {
        font-size: 6vw;
        br {
            display: none;
        }

    }
}
.page-section {
    margin: 70px 0;
    @include orientation-portrait {
        margin: 30px 0;
        .page + & {
            margin-top: 0;
        }
    }
}