@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
    @font-face {
    font-family: $font-family;
    src:
        url('#{$file-path}.woff2') format('woff2'),
        url('#{$file-path}.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
    }
}

@mixin orientation-portrait {
    @media screen and (orientation:portrait) {
        @content;
    }
}

@mixin break-xxs-up {
    @media screen and (min-width: 360px) {
        @content;
    }
}
@mixin break-xs-up {
    @media screen and (min-width: 512px) {
        @content;
    }
}
@mixin break-sm-up {
    @media screen and (min-width: 768px) {
        @content;
    }
}
@mixin break-md-up {
    @media screen and (min-width: 1024px) {
        @content;
    }
}
@mixin break-lg-up {
    @media screen and (min-width: 1280px) {
        @content;
    }
}
@mixin break-xl-up {
    @media screen and (min-width: 1680px) {
        @content;
    }
}
@mixin break-xs-down {
    @media screen and (max-width: 511px) {
        @content;
    }
}
@mixin break-sm-down {
    @media screen and (max-width: 767px) {
        @content;
    }
}
@mixin break-md-down {
    @media screen and (max-width: 1023px) {
        @content;
    }
}
@mixin break-lg-down {
    @media screen and (max-width: 1279px) {
        @content;
    }
}
@mixin break-xl-down {
    @media screen and (max-width: 1679px) {
        @content;
    }
}

@mixin orientation-portrait {
    @media screen and (orientation: portrait) {
        @content;
    }
}
@mixin orientation-landscape {
    @media screen and (orientation: landscape) {
        @content;
    }
}

// grid settings
@mixin fwidth1 {
    flex: 0 0 8.33%;
    max-width: 8.33%;
}
@mixin fwidth2 {
    flex: 0 0 16.66%;
    max-width: 16.66%;
}
@mixin fwidth3 {
    flex: 0 0 25%;
    max-width: 25%;
}
@mixin fwidth4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
}
@mixin fwidth5 {
    flex: 0 0 41.66%;
    max-width: 41.66%;
}
@mixin fwidth6 {
    flex: 0 0 50%;
    max-width: 50%;
}
@mixin fwidth7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
}
@mixin fwidth8 {
    flex: 0 0 66.66%;
    max-width: 66.66%;
}
@mixin fwidth9 {
    flex: 0 0 75%;
    max-width: 75%;
}
@mixin fwidth10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
}
@mixin fwidth11 {
    flex: 0 0 91.66%;
    max-width: 91.66%;
}
@mixin fwidth12 {
    flex: 0 0 100%;
    max-width: 100%;
}
@mixin offset1 {
    margin-left: 8.33%;
}
@mixin offset2 {
    margin-left: 16.66%;
}
@mixin offset3 {
    margin-left: 25%;
}
@mixin offset4 {
    margin-left: 33.33%;
}
@mixin offset5 {
    margin-left: 41.66%;
}
@mixin offset6 {
    margin-left: 50%;
}
@mixin offset7 {
    margin-left: 58.33%;
}
@mixin offset8 {
    margin-left: 66.66%;
}
@mixin offset9 {
    margin-left: 75%;
}
@mixin offset10 {
    margin-left: 83.33%;
}
@mixin offset12 {
    margin-left: 91.66%;
}

@mixin overlay {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.4;
    z-index: 1;
}
@mixin abs100 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin abs-aligned {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@mixin abs-valigned {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
@mixin mx-auto {
    margin-left: auto;
    margin-right: auto;
}
@mixin valigned {
    display: inline-block;
    vertical-align: middle;
}
@mixin flex-centered {    
    display: flex;
    align-items: center;
    justify-content: center;
}

// transitions
@mixin transition-regular {
    transition-timing-function: ease-out;
    transition-duration: $transition-duration;
}
